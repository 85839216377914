.select {
  // margin-right: 15px
  margin-top: 20px; }
.filter-select {
  min-width: 120px;
  margin-right: 15px;
  cursor: pointer !important; }
.label {
  margin-bottom: 10px;
  color: #112554 !important; }

.item {
  background-color: #fff; }

.active-filter {
  display: flex;
  align-items: center;
  background-color: #F0F4F5;
  border-radius: 50px;
  height: 50px;
  padding: 0 20px;
  margin-right: 15px;
  margin-bottom: 20px; }

.close-icon {
  cursor: pointer;
  padding: 0 !important; }

.separator {
  border: 1px solid #BCD1D6;
  height: 24px;
  margin: 0 10px; }

.selected-filter-row {
  margin-top: 30px; }

.top-filter-row {
  display: flex;
  margin-top: -20px;
  align-items: center; }


.filter-select-input {
  border: 1px solid #cbcbcb;
  min-width: 120px;
  cursor: pointer !important;
  height: 52px;
  padding: 0 10px; }

.down-icon {
  padding: 0 !important;
  position: relative !important;
  right: 25px; }

.down-icon-absolute {
  padding: 0 !important;
  position: absolute !important;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; }

.advanced-filter-row {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #D3D3D3; }

.advanced-filter-options {
  padding: 10px 25px; }


.more-filters-btn {
  margin-top: 36px;
  text-transform: capitalize;
  font-weight: 700;
  cursor: pointer;
  color: #315bbe;
  position: relative; }
