.password-rule {
  margin-bottom: 2px !important; }

.modal {
  display: flex;
  align-items: center;
  justify-content: center; }

.modal-paper {
  background-color: #fff;
  padding: 20px; }

.success-message {
  margin-bottom: 20px !important; }
