.heading {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  color: #112554;
  margin-bottom: 30px; }

.export-button {
  background-color: #3B3A5F !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
  padding: 6px 20px !important; }

.csv-hidden-link {
  display: none; }

.safe7-question-heading {
  font-size: 21px;
  margin-bottom: 5px; }

.headings-container {
  display: flex;
  flex-direction: column; }
