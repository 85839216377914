.title {
  flex-grow: 1; }

.app-header {
  background: #fff !important;
  box-shadow: none !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  padding: 0 20px !important;
  border-bottom: 1px solid #D3D3D3;
  .centre-items {
    display: flex;
    flex-direction: row; }
  .nav-item {
    text-transform: capitalize;
    margin-right: 10px;
    color: #112554;
    font-family: "Roboto", sans-serif;
    padding: 0px 15px;
    cursor: pointer; }
  .logo {
    background-image: url('./../../assets/images/safe7-logo.png');
    background-size: cover;
    width: 122px;
    height: 58px;
    margin: 4px; }
  .header-item {
    display: flex;
    align-items: center;
    .icon {
      width: 34px !important;
      height: 23px !important;
      background-size: contain;
      background-repeat: no-repeat; }
    &.active {
      border-bottom: 2px solid; } }
  .header-icon {
        width: 17px;
        height: 19px;
        cursor: pointer; }

  .right-items {
    display: flex; }

  .padlock-icon {
    background-image: url('./../../assets/images/padlock.svg');
    background-size: cover; }

  .user-icon {
    background-image: url('./../../assets/images/user.svg');
    background-size: cover; }

  .dashboard-icon {
    background-image: url('./../../assets/images/icon-dashboard.svg'); }

  .stats-icon {
    background-image: url('./../../assets/images/icon-stats.svg');
    margin-top: -4px !important; }

  .safe7-icon {
    background-image: url('./../../assets/images/icon-safe7.svg'); }

  .invite-icon {
    background-image: url('./../../assets/images/icon-invite.svg'); }

  .admin-icon {
    background-image: url('./../../assets/images/icon-admin.svg');
    margin-top: -4px !important; }

  .logout-icon {
    background-image: url('./../../assets/images/icon-logout.svg'); }

  .profile-icon {
    background-image: url('./../../assets/images/icon-profile.svg'); }

  .export-icon {
    background-image: url('./../../assets/images/icon-export-menu.svg'); } }
