.status-select {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  padding: 18.5px 14px; }

.error-text {
  margin-bottom: 15px !important; }

.modal {
  display: flex;
  align-items: center;
  justify-content: center; }

.modal-paper {
  background-color: #fff;
  padding: 20px; }

.success-message {
  margin-bottom: 20px !important; }

