.verify .form-container {
  margin: auto;
  padding: 14px;
  width: 90%;
  max-width: 320px;
  transition: 0.4s;
  transition-timing-function: ease-out; }

.verify .form-container.faded-left {
  transform: translate(00px, 60px);
  opacity: 0; }

.verify .form-container.faded-right {
  transform: translate(60px);
  opacity: 0; }

.verify .left-col {
  background: #0f0;
  background: linear-gradient(55deg, rgba(240,240,240,1) 0%, rgba(0,118,192,0.3029586834733894) 40%, rgba(40,179,172,0.3029586834733894) 80%, rgba(240,240,240,1) 100%);
  background-image: url('../../../assets/images/register@2x.png');
  background-size: cover; }

.resend-btn {
  text-transform: capitalize !important;
  font-weight: 700 !important;
  color: #4665ad !important;
  font-size: 1.1em !important; }
