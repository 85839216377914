.login {
  width: 100vw;
  min-height: 100vh; }

.login .form-container {
  margin: auto;
  padding: 14px;
  width: 90%;
  max-width: 320px;
  transition: 0.4s;
  transition-timing-function: ease-out; }

.login .form-container.faded-left {
  transform: translate(00px, 60px);
  opacity: 0; }

.login .form-container.faded-right {
  transform: translate(60px);
  opacity: 0; }

.logo-round {
    background-image: url('../../../assets/images/safe7-logo.png');
    background-size: cover; }

.nhs-logo {
    background-image: url('../../../assets/images/nhs-logo-colour.jpg');
    background-size: cover; }

.left-col {
  background: #f00;
  min-height: 100vh; }


.login .left-col {
  background: rgb(240,240,240);
  background: linear-gradient(55deg, rgba(240,240,240,1) 0%, rgba(0,118,192,0.3029586834733894) 40%, rgba(40,179,172,0.3029586834733894) 80%, rgba(240,240,240,1) 100%);
  background-image: url('../../../assets/images/login@2x.png');
  background-size: cover; }

.right-col {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.form-group {
  height: 80px; }

.form-title {
  font-size: 1.777em;
  text-align: center;
  margin-bottom: 14px; }

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 1em !important; }

.form-submit {
  text-align: center;
  width: 100%;
  border-radius: 14px;
  padding: 4px 7px;
  border: none;
  background: #555;
  color: #fff;
  margin-bottom: 14px; }


.form-link {
  margin-bottom: 14px;
  text-align: center;
  font-weight: 700 !important; }
