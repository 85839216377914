.main-container.dashboard {
  margin-bottom: 40px; }

@media(min-width:768px) {
  .main-container.dashboard {
    max-width: 800px; } }

@media(min-width:1600px) {
  .main-container.dashboard {
    max-width: 1000px; } }

.dashboard-card {
  padding: 40px 20px 24px;
  background: #fff !important;
  border: 1px solid #eee !important;
  border-radius: 5px !important;
  position: relative;
  margin-top: 60px;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.1)!important; }

.dashboard-card .MuiTypography-h6 {
  font-weight: 600; }

.dashboard-card .MuiTypography-subtitle2 {
  color: #999;
  padding-bottom: 10px; }

.support-card {
  padding: 0px;
  background: #0076C0 !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
  box-shadow: none!important;
  margin-top: 12px; }

.support-card .MuiBox-root {
  margin-left: 30px;
  padding-top: 10px;
  padding-bottom: 20px; }

.support-card .MuiTypography-h6 {
  font-size: 1.5rem;
  font-weight: 600; }

.support-card .btn-theme {
  background: #fff !important;
  color: #28B3AC !important;
  font-weight: 700;
  border: none;
  box-shadow: none;
  margin-top: 20px;
  box-shadow: none!important; }

.dashboard .support-card .btn-theme {
  width: auto; }

.support-card .upper-dashboard-title {
  margin-top: 0px;
  margin-bottom: 10px; }

.support-graphic {
  background-image: url('./../../assets/images/support-graphic@2x.png');
  background-size: cover;
  width: 100%;
  height: calc(100% + 24px);
  margin-top: -12px;
  background-position: left center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-radius: 0 5px 5px 0;
  overflow: hidden; }

.dashboard-card-icon-container {
  width: 80px;
  height: 80px;
  background: #0076C0;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  left: calc(50% - 40px);
  top: -40px; }

.dashboard-card-icon {
  margin: auto;
  top: 23px;
  position: relative;
  width: 27px;
  height: 34px; }

.reports-icon-white {
  background-image: url('./../../assets/images/reports-white.svg');
  background-size: cover; }

.profile-icon-white {
  background-image: url('./../../assets/images/user-white.svg');
  background-size: cover; }

.dashboard .btn-theme {
  max-width: 180px;
  text-transform: capitalize;
  font-weight: 600;
  box-shadow: none!important; }

.network-graphic {
  background-image: url('./../../assets/images/network.svg');
  background-size: cover;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 33.333vw;
  height: 33.333vw;
  z-index: -1;
  width: 100%;
  background-position: bottom right;
  background-size: auto 100%;
  background-repeat: no-repeat; }
